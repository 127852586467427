@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #ffffff00;
    /* background: #f1f1f1; */
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}